import { navigate } from 'gatsby';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { GlobalState } from '../state/@types/redux.interface';
import { passwordAuthorizeToEventAction } from '../state/authorize.reducer';
import { fetchEventByHashAction, setEventLoadingStatusAction } from '../state/event.reducer';
import { getQueryParam } from '../_utils/queryParams';
import { setLocalStorageItem } from '../_utils/localStorage';
import logoSEO from '../images/logoPNG.png';
// import NotFoundPage from './404';

const isPublic = (votersAccess) => votersAccess === 'public';

const authorizeToEvent = async () => {
	navigate('/room');
};

const EVENT_HASH = 'eventHash';
const CODE = 'code';

const IndexPage: FunctionComponent = ({ location }) => {
	const [eventHash, setEventHash] = useState<string>(null);
	const [isCode, setIsCode] = useState<string>(null);
	const dispatch = useDispatch();
	const eventReducer = useSelector((state: GlobalState) => state.event);
	const authorizeReducer = useSelector((state: GlobalState) => state.authorize);
	const { error: eventError, data: eventData } = eventReducer;
	const { voterEventAccess: authorizedVoter, loginLoading } = authorizeReducer;

	const updateQueryParam = () => {
		const tempEventHash = getQueryParam(EVENT_HASH);
		if (tempEventHash) {
			setLocalStorageItem('eventHash', tempEventHash);
			setEventHash(tempEventHash);
		} else {
			navigate('/404');
		}
		setIsCode(getQueryParam(CODE));
	};

	useEffect(() => {
		updateQueryParam();
		dispatch(setEventLoadingStatusAction(true));
		navigator.serviceWorker.getRegistrations().then((registrations) => {
			for (const registration of registrations) {
				registration.unregister();
			}
		});
	}, [location]);

	useEffect(() => {
		if (eventHash) {
			dispatch(fetchEventByHashAction(eventHash));
		}
	}, [eventHash]);

	useEffect(() => {
		if (!eventData || !eventHash) {
			return;
		}
		if (moment().utc().isAfter(eventData.accessEndAt)) {
			navigate('/eventEnded');
		}
		if (!isPublic(eventData.votersAccess) && !eventData.isPreview) {
			navigate('/authorize', {
				state: {
					isCode,
					eventData,
				},
			});
		}
		if ((isPublic(eventData.votersAccess) || eventData.isPreview) && !loginLoading) {
			dispatch(passwordAuthorizeToEventAction(eventHash, null));
		}
	}, [eventData, eventHash]);

	useEffect(() => {
		if (eventError && !eventData) {
			navigate('/404');
		}
	}, [eventError]);

	useEffect(() => {
		if (authorizedVoter) {
			authorizeToEvent();
		}
	}, [authorizedVoter]);

	return (
		<Layout>
			<SEO
				description="Wyborek | Głosuj szybko i łatwo!"
				image={logoSEO}
				title="Wyborek"
			/>
		</Layout>
	);
};

export default IndexPage;
