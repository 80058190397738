import { isWindow } from './windowUndefined';

export const getLocalStorageItem = (key: string): string => {
	if (isWindow()) {
		return localStorage.getItem(key);
	}
};

export const setLocalStorageItem = (key: string, value: string) => {
	if (isWindow()) {
		localStorage.setItem(key, value);
	}
};
